import React, { useState } from 'react'
// import ReactQuill from 'react-quill'
import { Typography } from 'antd'
// import 'react-quill/dist/quill.snow.css'
import FieldWrapper from '../FieldWrapper'

const { Title } = Typography

const RichTextField = (props) => {
  const { name, onChange, values, placeholder } = props
  return (
    <FieldWrapper {...props}>
      {() => (
        <Title
          className="RichTextComponent"
          name={name}
          placeholder={placeholder || `input ${name}`}
          contentEditable
          suppressContentEditableWarning
          // value={initialValues.initValues[name]}
          onBlur={(e) => onChange(name, e.target.textContent)}
        >
          {values[name]}
        </Title>
      )}
    </FieldWrapper>
  )
}

export default RichTextField
